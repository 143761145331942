import i18n from '../language/Language'
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const _this = i18n.global
/* 访问来源检查 */
const isPhone = function () {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

/* 核心路由(路由已分类, 请分类维护) */
const routesHome = [
  {
    path: '/',
    component: HomeView,
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/HomeView.vue'),
    meta: {
      title: _this.t('routers.login')
    }
  },
  {
    path: '/userlogin',
    name: 'UserLogin',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login/LoginFrame.vue'),
    meta: {
      title: _this.t('routers.login')
    }
  },
  {
    path: '/accountlogin',
    name: 'AccountLogin',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login/AccountLoginFrame.vue'),
    meta: {
      title: _this.t('routers.login')
    }
  },
  /* *******aigc / 动态漫  设置页面************/
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/H5/aigcSetting.vue')
  },
  {
    path: '/accountSecurity',
    name: 'accountSecurity',
    component: () => import('../views/H5/accountSecurity.vue')
  },
  // aigc / 动态漫 绑定手机号
  {
    path: '/bindPhone',
    name: 'bindPhone',
    component: () => import('../views/H5/components/bindPhone.vue')
  },
  {
    path: '/setPwd',
    name: 'setPwd',
    component: () => import('../views/H5/components/setPwd.vue')
  },
  {
    path: '/editPwd',
    name: 'editPwd',
    component: () => import('../views/H5/components/editPwd.vue')
  },
  {
    path: '/editPhone',
    name: 'editPhone',
    component: () => import('../views/H5/components/editPhone.vue')
  },
  /* *************************************适配过渡页面*******************************************************/
  {
    path: '/foreignEditIframe',
    name: 'foreignEditIframe',
    component: () => import('../views/Personal/foreignEditIframe.vue')
  },
  /* ********************************************************************************************/
  // 分享提取页面
  {
    path: '/shareIndex',
    name: 'shareIndex',
    component: () => import('../views/share/SharingExtraction.vue')
  },
  // 分享图片展示页面
  {
    path: '/bigPicture',
    name: 'bigPicture',
    component: () => import('../views/share/SharedImages.vue')
  },
  // 云空间扩容购买窗口
  {
    path: '/purchaseWindow',
    name: 'purchaseWindow',
    component: () => import('../components/gbOrderInfo.vue')
  },
  // 用户中心
  {
    path: '/front/Personal',
    name: 'Personal',
    component: () => import('@/views/Layout/layoutMain.vue'),
    redirect: '/front/Personal/PersonalInformation',
    children: [
      // 首页
      {
        path: 'PersonalInformation',
        name: 'PersonalInformation',
        component: () => import('@/views/cloud/mainHome.vue'),
        meta: {
          title: _this.t('routers.mainPage')
        }
      },
      // 云空间--首页
      {
        path: 'CloudHome',
        name: 'CloudHome',
        component: () => import('@/views/cloud/CloudHome.vue'),
        meta: {
          title: _this.t('routers.cloudSpace')
        }
      },
      // 创作者中心
      {
        path: 'CreatorHome',
        name: 'CreatorHome',
        component: () => import('@/views/creator/CreatorHome.vue'),
        meta: {
          title: '创作者中心'
        }
      },
      // 发布作品
      {
        path: 'UploadPicture',
        name: 'UploadPicture',
        component: () => import('@/views/works/UploadWorks.vue'),
        meta: {
          title: '发布作品'
        }
      },
      // vip更多
      {
        path: 'moreBenefits',
        name: 'moreBenefits',
        component: () => import('@/views/Personal/moreBenefits.vue')
      },
      // 个人信息
      // {
      //   path: 'PersonalInformation',
      //   name: 'PersonalInformation',
      //   component: () => import('@/views/Personal/PersonalInformation.vue'),
      //   meta: {
      //     title: '用户中心'
      //   }
      // },
      // 会员激活
      {
        path: 'activationVip',
        name: 'activationVip',
        component: () => import('@/views/activation/memberActivation.vue')
      },
      // 我的下载
      {
        path: 'myDownloads',
        name: 'myDownloads',
        component: () => import('@/views/ApplyAuthor/myDownloads.vue')
      },
      // nft相册
      {
        path: 'PersonalPhotoNft',
        name: 'PersonalPhotoNft',
        component: () => import('@/views/activity/nft/nftAlbum.vue')
      },
      // 消息
      {
        path: 'message',
        name: 'message',
        component: () => import('@/views/Personal/messageIndex.vue')
      },
      // 订单信息
      {
        path: 'orderInformation',
        name: 'orderInformation',
        component: () => import('@/views/SalesOrder/orderInfo.vue')
      },
      // 优惠卡券
      {
        path: 'discountsCoupon',
        name: 'discountsCoupon',
        component: () => import('../views/SalesOrder/discountsCoupon.vue')
      },
      // 分享
      {
        path: 'ShareRecord',
        name: 'ShareRecord',
        component: () => import('@/views/share/ShareRecord.vue'),
        meta: {
          title: '分享记录'
        }
      },
      // 企业会员（管理页）
      {
        path: 'organizeView',
        name: 'organizeView',
        component: () => import('@/views/organize/organizeView.vue'),
        meta: {
          title: '企业会员'
        }
      },
      // 财富管理
      {
        path: 'WealthView',
        name: 'WealthView',
        component: () => import('@/views/organize/WealthView.vue'),
        meta: {
          title: '企业会员'
        }
      },
      // 活动赛事
      {
        path: 'activitiesView',
        name: 'activitiesView',
        component: () => import('@/views/activity/activitiesView.vue'),
        meta: {
          title: '活动赛事'
        }
      },
      // 安装包管理
      {
        path: 'ManagePackages',
        name: 'ManagePackages',
        component: () => import('@/views/community/ManagePackages.vue'),
        meta: {
          title: '安装包管理'
        }
      },
      // 首页素材管理
      {
        path: 'ManageMaterialsIndex',
        name: 'ManageMaterialsIndex',
        component: () => import('@/views/community/ManageMaterialsIndex.vue'),
        meta: {
          title: '首页素材管理'
        }
      },
      // 作品审核
      {
        path: 'WorksReview',
        name: 'WorksReview',
        component: () => import('@/views/community/WorksReview.vue'),
        meta: {
          title: '作品审核'
        }
      },
      // 活动宣传
      {
        path: 'PromotionalActivities',
        name: 'PromotionalActivities',
        component: () => import('@/views/community/PromotionalActivities.vue'),
        meta: {
          title: '活动宣传'
        }
      },
      // 合伙人中心
      {
        path: 'PartnerHome',
        name: 'PartnerHome',
        component: () => import('@/views/partner/PartnerHome.vue'),
        meta: {
          title: '合伙人中心'
        }
      }
    ]
  },

  // (pc端app内嵌)云空间
  {
    path: '/front/Cloud',
    name: 'Cloud',
    component: () => import('@/views/cloud/CloudHome.vue')
  },

  // 发布作品
  {
    path: '/CloudUploadPicture',
    name: 'CloudUploadPicture',
    component: () => import('@/views/works/UploadWorks.vue')
  },

  // (pc端app内嵌)申请账号
  {
    path: '/front/applyorganizeuser',
    name: 'applyOrganizeUser',
    component: () => import('@/views/organize/applyOrganizeUser.vue')
  },

  /* 脱机激活start */
  {
    path: '/activation/machine',
    name: 'machine',
    component: () => import('@/views/activation/macHineCode.vue'),
    meta: {
      title: '机器码'
    }
  },
  {
    path: '/activation/offlineActive',
    name: 'offlineActive',
    component: () => import('@/views/activation/offlineActive.vue'),
    meta: {
      title: '脱机激活'
    }
  },
  {
    path: '/activation/result',
    name: 'activateResult',
    component: () => import('@/views/activation/serialResult.vue'),
    meta: {
      title: '序列号'
    }
  },
  // 统信活动
  {
    path: '/txActivity',
    name: 'txActivity',
    component: () => import('../views/activity/expireActivity.vue')
  },
  // 教师节活动
  {
    path: '/teacherActivity',
    name: 'teacherActivity',
    component: () => import('../views/activity/expireActivity.vue')
  },
  // 中秋节活动
  {
    path: '/midAutumnActivity',
    name: 'midAutumnActivity',
    component: () => import('../views/activity/expireActivity.vue')
  },
  // 教装节活动
  {
    path: '/teachClothesActivity',
    name: 'teachClothesActivity',
    component: () => import('../views/activity/expireActivity.vue')
  },
  // 重阳节活动
  {
    path: '/doubleNinthActivity',
    name: 'doubleNinthActivity',
    component: () => import('../views/activity/expireActivity.vue')
  },
  // 通用活动: 需要传参活动编号
  {
    path: '/publicActivity',
    name: 'publicActivity',
    component: () => import('../views/Personal/publicActivity.vue')
  },
  // 通用领取优惠券: 需要根据需求自行调整活动页UI（今次是金秋特惠活动）
  {
    path: '/couponPublic',
    name: 'couponPublic',
    component: () => import('@/views/activity/coupon/getCoupon.vue'),
    meta: {
      title: '亦心科技特惠礼券等你来领取'
    }
  },
  {
    path: '/coupon89',
    name: 'coupon89',
    component: () => import('@/views/activity/coupon/getCoupon.vue'),
    meta: {
      title: '亦心科技金秋特惠等你来领取'
    }
  },
  {
    path: '/coupon701',
    name: 'coupon701',
    component: () => import('../views/activity/expireActivity.vue'),
    meta: {
      title: '亦心科技701免费优惠券等你抢'
    }
  },
  {
    path: '/coupon618',
    name: 'coupon618',
    component: () => import('../views/activity/expireActivity.vue'),
    meta: {
      title: '亦心科技618免费优惠券等你抢'
    }
  },
  {
    path: '/expireActivity',
    name: 'expireActivity',
    component: () => import('../views/activity/expireActivity.vue'),
    meta: {
      title: '亦心科技活动已经结束了'
    }
  },
  // 邀请新用户活动
  {
    path: '/inviteNewUser',
    name: 'inviteNewUser',
    component: () => import('@/views/activity/invite/oldBringsNew.vue'),
    meta: {
      title: '邀请好友注册悟空图像活动'
    }
  },
  // (pc端app内嵌)AI闪绘内嵌弹窗
  {
    path: '/front/flashPaintDialog',
    name: 'flashPaintDialog',
    component: () => import('@/views/activity/flashpaint/flashPaintDialog.vue')
  },
  // AI闪绘活动
  {
    path: '/flashPaintActivity',
    name: 'flashPaintActivity',
    component: () => import('@/views/activity/flashpaint/flashPaintActivity.vue')
  },
  // AI闪绘活动 单独闪绘版本
  {
    path: '/flashPaintActivitypad',
    name: 'flashPaintActivitypad',
    component: () => import('@/views/activity/flashpaint/flashPaintActivityforpad.vue')
  },
  // AI闪绘指南
  {
    path: '/flashPaintGuide',
    name: 'flashPaintGuide',
    component: () => import('@/views/activity/flashpaint/flashPaintGuide.vue')
  },
  // pad注销账号
  {
    path: '/logoff',
    name: 'logoff',
    component: () => import('@/views/Personal/editUser/logoffAccount.vue')
  }
]

/* 活动路由分支
 * nft
*/
const routesActivity = [
/* NFT活动页start */
  {
    path: '/nft',
    redirect: '/nft/index'
  },
  {
    path: '/nft/index', // 活动首页
    name: 'nftIndex',
    component: () => import('@/views/activity/nft/nftIndex.vue'),
    meta: {
      title: '悟空图像NFT'
    }
  },
  {
    path: '/nft/open', // 开启页(普通)
    name: 'nftOpen',
    component: () => import('@/views/activity/nft/nftOpen.vue'),
    meta: {
      title: '悟空图像NFT'
    }
  },
  {
    path: '/nft/openPlus', // 开启页(高级定制)
    name: 'openPlus',
    component: () => import('@/views/activity/nft/openPlus.vue'),
    meta: {
      title: '悟空图像NFT'
    }
  },
  {
    path: '/nft/myNft', // 我的nft头像
    name: 'myNft',
    component: () => import('@/views/activity/nft/myNft.vue'),
    meta: {
      title: '悟空图像NFT'
    }
  },
  {
    path: '/giftGet', // 活动领取结果页
    name: 'giftGet',
    component: () => import('@/views/Personal/messageGiftGet.vue'),
    meta: {
      title: '领取'
    }
  },
  {
    path: '/login/back', // 微信登录PC 回调
    name: 'loginCallBack',
    component: () => import('@/views/Login/loginCallBack.vue'),
    meta: {
      title: _this.t('routers.user')
    }
  },
  {
    path: '/activepsvip',
    name: 'activepsvip',
    component: () => import('@/views/activation/activePcVip.vue'),
    meta: {
      title: '激活'
    }
  },
  // // 绑定微信
  // {
  //   path: '/bindVx',
  //   name: 'bindVx',
  //   component: () => import('@/views/Personal/editUser/bindVX.vue'),
  //   meta: {
  //     title: '绑定微信'
  //   }
  // },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/activity/expireActivity.vue'),
    meta: {
      title: '双十二抽取惊喜优惠券'
    }
  },
  {
    path: '/christmas',
    name: 'christmas',
    component: () => import('@/views/activity/expireActivity.vue'),
    meta: {
      title: '欢乐圣诞 一路同行'
    }
  },
  {
    path: '/newYear',
    name: 'newYear',
    component: () => import('@/views/activity/coupon/getNewYear.vue'),
    meta: {
      title: '2024丨新岁启封 筑梦前行'
    }
  }
  // {
  //   path: '/nft/scanHint', //活动首页
  //   name: 'scanHint',
  //   component: () => import('../views/activity/nft/scanHint.vue'),
  //   meta: {
  //       title: '悟空图像NFT',
  //   },
  // },

/* NFT活动页end */
]

/* 套版 */
const routesOther = [
  // 套版
  {
    path: '/uploadTemplate',
    name: 'uploadTemplate',
    component: () => import('../views/Template/uploadTemplate.vue'),
    meta: {
      title: '套版'
    }
  },
  // 详情
  {
    path: '/tempplate_details',
    name: 'tempplate_details',
    component: () => import('../views/Template/tempplate_details.vue'),
    meta: {
      title: '详情'
    }
  },
  // 支付
  {
    path: '/payment_buy',
    name: 'payment_buy',
    component: () => import('../views/Template/payment_buy.vue'),
    meta: {
      title: '支付'
    }
  },
  // 支付成功
  {
    path: '/buy_success',
    name: 'buy_success',
    component: () => import('../views/Template/buy_success.vue'),
    meta: {
      title: '支付成功'
    }
  }
]

/* 总路由 */
const routes = [...routesHome, ...routesActivity, ...routesOther]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  linkActiveClass: 'routerActive',
  routes
})

/* 路由守卫 */
router.beforeEach((to, from, next) => {
  const toName = to.name
  if (!to.meta.title) {
    window.document.title = _this.t('routers.user')
  } else {
    window.document.title = to.meta.title
  }
  // 首页重定向
  if (toName === 'index' || toName === 'Index' || toName === 'Login') {
    router.push(window.SITE_CONFIG.indexUrl)
  }
  // 活动页跳转拦截
  if (toName === 'loginHome1' || toName === 'loginHome2' || toName === 'loginHome3') {
    if (from.name == null) {
      router.push('/activity/login/home')
    }
  }
  // 企业会员pc和移动端跳转
  if (toName === 'vipCompany') {
    if (isPhone() !== null) {
      router.push('/vip/companyP')
    }
  }
  // iframe 中 登录页要切换rem基数值，否则消息提示弹窗过小(看不清)
  if (toName === 'UserLogin') {
    window.resetRem(75)
  } else if (toName === 'purchaseWindow' || toName === 'giftGet') {
    window.resetRem(60.6)
  } else if (toName === 'Cloud' || toName === 'CloudUploadPicture') {
    window.resetRem(25)
  } else if (toName === 'applyOrganizeUser') {
    window.resetRem(78.2)
  } else if (toName === 'flashPaintDialog' || toName === 'logoff') {
    window.resetRem(25)
  } else if (toName === 'accountSecurity' || toName === 'bindPhone' || toName === 'editPhone' || toName === 'setPwd' || toName === 'editPwd') {
    window.resetRem(50)
  } else {
    console.log(toName)
    window.resetRem(19)
  }
  next()
})

export default router
